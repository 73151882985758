import { http } from '~/utils/httpClient'
import { ListSignatureSort, SignatureType } from './signatures.constants'

export type ListSignaturePayload = {
  page: number,
  search?: string,
  type?: SignatureType,
  sort?: ListSignatureSort,
}

export const listSignature = ({
  page,
  search,
  type,
  sort,
}: ListSignaturePayload) => http.$get<void>(`signature`, {
  params: {
    page,
    search: search || undefined,
    type,
    sort,
  },
})

export type CreateSignaturePayload = {
  files: Blob[],
  type: SignatureType,
  title: string,
  renduEstimationId?: string,
  mandatId?: string,
  mandatAvenantId?: string,
}

export const createSignature = ({
  files,
  type,
  title,
  renduEstimationId,
  mandatId,
  mandatAvenantId,
}: CreateSignaturePayload) => {
  const form = new FormData()

  form.append('type', type)
  form.append('title', title)

  if (renduEstimationId) {
    form.append('renduEstimationId', renduEstimationId)
  }

  if (mandatId) {
    form.append('mandatId', mandatId)
  }

  if (mandatAvenantId) {
    form.append('mandatAvenantId', mandatAvenantId)
  }

  files.forEach((file) => {
    form.append('fileContents[]', file)
  })

  return http.$post<void>(`signature`, form)
}

export const cancelSignature = (signatureId: string) => http.$post<void>(`signature/${signatureId}/cancel`)

export const downloadSignatureDocument = (signatureId: string) => http.$get<void>(`signature/${signatureId}/document/download`, {
  responseType: 'blob',
})